export const getStreamUrl = async (url: string) => {
	const fetchData = () =>
		fetch(`https://api.cobalt.tools/api/json`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify({
				url: url
			})
		}).then(res => res.json()) as Promise<{
			status: 'error' | 'redirect' | 'stream' | 'success' | 'rate-limit' | 'picker';
			text?: string;
			url: string;
		}>;

	let retryCount = 0;
	let data = await fetchData();
	while (data.status !== 'stream' && retryCount < 4) {
		retryCount++;
		await new Promise(resolve => setTimeout(resolve, 250));
		data = await fetchData();
	}
	if (data.status === 'stream') return data.url;
	return null;
};
